/** @jsxImportSource @emotion/react */
import { Button, Typography, useTheme } from "@mui/material";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
// import couchImage from "../../../../assets/main_illustrations/couch.png";
import cloudImage from "../../../../assets/people_images/cloud_import.png";
import CertiblokIcon from "../../../../utils/components/icons/CertiblokIcon";
import LoadingTextButton from "../../../../utils/components/LoadingTextButton";
import WhiteModal from "../../../../utils/components/WhiteModal";
import { useBetterMediaQuery } from "../../../../utils/hooks/useBetterMediaQuery";
import CloudTabs from "./components/CloudTabs";
import { useHandleDropboxAuth, useHandleGoogleDriveAuth, useHandleOneDriveAuth } from "./hooks/useHandleCloudsAuth";
import { useGetAllCloudRootLevelDocs } from "./hooks/useHandleGetFolderAndDocs";
import { CloudSource } from "./ImportFromCloudContentModal";

type ImportfromCloudModalProps = {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	isFirstLogin?: boolean;
};

const ImportFromCloudModal: FC<ImportfromCloudModalProps> = ({ open, setOpen, isFirstLogin }) => {
	const { t } = useTranslation();
	const theme = useTheme();
	const isMd = useBetterMediaQuery("md");

	const [selectedTab, setSelectedTab] = useState<number>(0);
	const tabs = ["googleDrive", "dropbox", "oneDrive"] as CloudSource[];

	const [searchParams, setSearchParams] = useSearchParams();

	const onClose = () => {
		setSelectedTab(0);
		setOpen(false);
	};

	const { mutate: handleGoogleDriveAuth, isLoading: isGoogleDriveAuthLoading } = useHandleGoogleDriveAuth();
	const { mutate: handleDropboxAuth, isLoading: isDropboxAuthLoading } = useHandleDropboxAuth();
	const { mutate: handleOneDriveAuth, isLoading: isOneDriveAuthLoading } = useHandleOneDriveAuth();

	const handleImportAuth = () => {
		switch (tabs[selectedTab]) {
			case "googleDrive":
				handleGoogleDriveAuth();
				break;
			case "dropbox":
				handleDropboxAuth();
				break;
			case "oneDrive":
				handleOneDriveAuth();
				break;
			default:
				break;
		}
	};

	const { mutateAsync: getRootLevel, isLoading: isLoadingAuth } = useGetAllCloudRootLevelDocs({
		enabledCloud: tabs[selectedTab],
		onAuthSuccess: () => {
			setOpen(false);
			const newSearchParams = new URLSearchParams(searchParams);
			newSearchParams.set("linkedCloud", tabs[selectedTab]);
			setSearchParams(newSearchParams);
			setSelectedTab(1);
		},
		onAuthError: () => {
			handleImportAuth();
		},
	});

	const isLoading = isGoogleDriveAuthLoading || isDropboxAuthLoading || isOneDriveAuthLoading || isLoadingAuth;

	return (
		<WhiteModal
			open={open}
			onClose={() => {
				setOpen(false);
			}}
			containerSx={{
				minWidth: "80vw",
				minHeight: "80vh",
				maxHeight: "80vh",
				maxWidth: "90vw",
				borderRadius: 10,
				padding: 0,
				display: "flex",
				flexDirection: "column",
			}}
		>
			<>
				<div className="w-full flex-1 flex flex-row rounded-[40px] overflow-hidden">
					{isMd && (
						<div className="basis-1/2 bg-[#EFF7E6] max-w-[50%] flex items-center justify-center shadow-12">
							<img src={cloudImage} alt="" className="object-cover h-[100%] max-w-[150%] max-h-[100%]" />
						</div>
					)}
					<div className="flex-1 flex-shrink-0 bg-white p-6 flex flex-col items-center justify-between gap-2">
						<Button className="items-center self-end" onClick={() => onClose()}>
							<Typography variant="button" className="text-black">
								{t("global.close")}
							</Typography>
							<CertiblokIcon name="close" color={"black"} />
						</Button>
						<div>
							<Typography variant={isMd ? "h4" : "h6"} className="m-auto font-extrabold text-center max-w-[350px]">
								{t("cloudImport.importInCertiblok")}
							</Typography>
							<Typography variant="body2" className="text-center text-black/[0.74] mt-2">
								{t("cloudImport.importModalDescription")}
							</Typography>
						</div>
						<div className="w-full">
							<Typography variant="subtitle1" className="text-center">
								{t("cloudImport.selectCloud")}
							</Typography>
							<div className="mt-6 w-full">
								<CloudTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
							</div>
						</div>
						<div className="w-full">
							<LoadingTextButton
								variant="contained"
								color="secondary"
								size="large"
								sx={{ ...theme.typography.button, width: "100%" }}
								disabled={selectedTab === undefined || isLoading}
								loading={isLoading}
								onClick={() => {
									getRootLevel();
									// handleImportAuth();
								}}
							>
								{t("cloudImport.importFile")}
							</LoadingTextButton>
							<Button color="secondary" className="mt-4 w-full" onClick={() => onClose()}>
								{isFirstLogin ? t("settings.skipStep") : t("global.cancel")}
							</Button>
						</div>
					</div>
				</div>
			</>
		</WhiteModal>
	);
};

export default ImportFromCloudModal;
